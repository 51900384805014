<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <input type="file" class="form form-control" @change="onChange" />
            </div>
            <div class="col-auto text-right">
              <button class="btn btn-primary" @click="doUploadBuyer">
                Import Buyer Data
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <input type="file" class="form form-control" @change="onChange" />
            </div>
            <div class="col-auto text-right">
              <button class="btn btn-primary" @click="doUploadProduct">
                Import Product Data
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <input type="file" class="form form-control" @change="onChange" />
            </div>
            <div class="col">
              <input
                type="text"
                class="form form-control"
                v-model="tahun"
                placeholder="Tahun Jurnal"
              />
            </div>
            <div class="col-auto text-right">
              <button class="btn btn-primary" @click="doUploadSalesJurnal">
                Import Sales Jurnal
              </button>
            </div>
          </div>

          <hr />

          <vue-excel-editor v-if="jsondata" v-model="jsondata" filter-row />
          <pre>
            {{ logMessage }}
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { read, utils } from "xlsx";
import Service from "@/services/base.service";

export default {
  components: {
    // VueExcelEditor,
  },
  data() {
    return {
      file: null,
      jsondata: null,
      logMessage: [],
      tahun: "",
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = read(bstr, { type: "binary" });
          // /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // /* Convert array of arrays */
          const data = utils.sheet_to_json(ws, { header: 1 });
          this.jsondata = data;
        };
        reader.readAsBinaryString(this.file);
      }
      // console.log(event);
    },
    doUploadBuyer() {
      this.doUploadMasterBuyer();
    },
    async doUploadMasterBuyer() {
      const rawData = toRaw(this.jsondata);
      // this.createPerson(rawData[1]);
      const regionService = new Service("region");
      const countryService = new Service("country");
      const buyerService = new Service("buyer");

      for (const row of rawData.slice(1)) {
        const regionName = row[0].trim();
        const countryName = row[1].trim();
        const buyerName = row[2].trim();

        const region = regionName
          ? await regionService.createData({ name: regionName })
          : null;

        const country = countryName
          ? await countryService.createData({
              name: countryName,
              regionId: region ? region.data.id : null,
            })
          : null;

        const buyer = await buyerService.createData({
          name: buyerName,
          countryId: country ? country.data.id : null,
        });

        this.logMessage.push(
          `PROCESS BUYER: ${buyer.data.name} - ${
            country ? country.data.name : ""
          } - ${region ? region.data.name : ""}`
        );
      }
    },
    async doUploadSalesJurnal() {
      const rawData = toRaw(this.jsondata)
        .slice(1)
        .filter((f) => {
          return f.length > 0 && f[0] != undefined;
        })
        .map((r) => {
          // console.log(r);
          // const productName = r[1].split(" - ");
          return {
            customer_name: r[1].trim(),

            product_name: r[4].trim(),
            product_code: r[5].trim(),
            product_satuan: r[7],
            product_kategori: r[15] ? r[15] : "",
            product_volume: r[16] ? r[16] : "",
            product_packaging: r[17] ? r[17] : "",
            product_brand: r[18] ? r[18] : "",

            order_tahun: r[6],
            order_qty: r[8],
            order_qty_foc: r[9] ? r[9] : "",
            order_qty_mp: r[10] ? r[10] : "",

            order_dpp: r[11],
            order_ppn: r[12],
            order_ppn_bm: r[13],
            order_total: r[14],
          };
        });
      const result = Object.groupBy(
        rawData,
        ({ customer_name }) => customer_name
      );
      const buyerService = new Service("buyer/sales");
      const entries = Object.entries(result);
      for (let [key, value] of entries) {
        // GET CUSTOMER ID
        const sales = await buyerService.createData({
          buyer: key,
          tahun: this.tahun,
          products: value,
        });
        console.log(sales);
      }
    },
    async doUploadProduct() {
      const rawData = toRaw(this.jsondata)
        .slice(1)
        .filter((f) => {
          return f.length > 0 && f[0] != undefined;
        })
        .map((m) => {
          return {
            code: m[1],
            volume: m[2] ? m[2] : "",
            packaging: m[3] ? m[3] : "",
            brand: m[4] ? m[4] : "",
          };
        });
      const productService = new Service("product/upsert");

      for (let product of rawData) {
        const upsert = await productService.createData(product);
        console.log(upsert);
      }
    },
  },
};
</script>
